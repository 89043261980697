/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "7d0adc6634ac4b3896518bc95d028709",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "7d0adc6634ac4b3896518bc95d028709",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://spppzrje25cnnhmdjkyq4qxmpy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:3e8eeb7c-6b43-4791-91af-68202c237866",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_J5QfseNw8",
    "aws_user_pools_web_client_id": "47v2vcajjt1hcgf1dor27kpnup",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "websiteapp-storage-fdb68492737c0-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
